import { render, staticRenderFns } from "./fractal-pagination.vue?vue&type=template&id=7fb39dfb&scoped=true&"
import script from "./fractal-pagination.vue?vue&type=script&lang=js&"
export * from "./fractal-pagination.vue?vue&type=script&lang=js&"
import style0 from "./fractal-pagination.vue?vue&type=style&index=0&id=7fb39dfb&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb39dfb",
  null
  
)

export default component.exports